@import '../../assets/styles/variables.scss';

.tile {
  transition: all ease 1s;
  background: $background-color;
  margin-bottom: 20px;
  cursor: pointer;

  .image {
    width: 100%;
    height: 30vw;
    max-height: 140px;
    object-fit: cover;
  }

  .linkcontainer {
    height: 2em;
    line-height: 2em;
    padding: 15px;
    color: $primary-color;

    .heading {
      text-align: left;

      float: left;

      &__small {
        font-size: 1em;
      }

      &__medium {
        font-size: 1.4em;
      }

      &__large {
        font-size: 1.6em;
      }
    }

    .icon {
      font-size: 1.6em;
      text-align: right;
      margin-left: auto;
      float: right;
    }
  }
}
