@import '../../assets/styles/variables.scss';

.wrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;

  .memberSelector {
    margin: 0 1rem;
    .memberLink {
      padding: 1rem;
      text-align: center;
      background: rgba(255, 255, 255, 0.4);
      color: black;
      cursor: pointer;

      &__active {
        background: $white;
        color: $primary-color;
      }
    }
  }
}
