$white: #ffffff;

$primary-color: #008000;
$primary-accent-color: #ffffff;

$background-color: #f6f6f6;

$black: #000000;

$content-padding: 1em;
$content-margin: 1em;

$footer-background-color: #3d3d3f;
$footer-accent-color: $white;

$text-shadow: 3px 3px 3px #777;
