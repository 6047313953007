@import '../../assets/styles/variables.scss';

.wrapper {
  position: sticky;
  top: 0;

  z-index: 50;
  //width: 100vw;
  height: 90px;
  background: $primary-color;

  .company {
    font-family: Nunito, sans-serif;
    font-size: 30px;
    letter-spacing: 2px;
    color: $primary-accent-color;
  }

  .slogan {
    margin-left: 5px;
    padding-left: 5px;
    font-size: 20px;
    color: $primary-accent-color;
  }

  .logo {
    height: 100%;
  }

  .homeicon {
    position: absolute;
    right: 20px;
    font-size: 2em;
    color: $primary-accent-color;
    line-height: 90px;
    margin-right: 1rem;
  }
}
