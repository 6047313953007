@import '../../assets/styles/variables.scss';

.member {
  background: $white;
  margin: 0 1rem;
  padding: 1rem;
  .name {
    color: $primary-color;
    margin-top: 0;
  }

  .icon {
    margin-right: 5px;
  }
  .text {
    text-decoration: none;
    color: #000000;
    cursor: pointer;
  }

  .downloadCard {
    width: calc(100% - 2em);
    padding: $content-padding;
    font-size: 1em;
    background: $primary-color;
    color: $primary-accent-color;
    border: none;
    text-decoration: none;
    float: right;
    text-align: center;
  }
}

:global {
  .timeline {
    font-size: 1em;
    margin: 0 auto !important;
    margin-top: 2rem !important;
    .entry {
      font-size: 1em;
      .title {
        font-size: 1em;
        .timeline-item-date {
          background: transparent;
          font-size: 1em;
          .timeline-item-dateinner {
            font-size: 1em;
            color: $primary-accent-color;
            background-color: $primary-color;
          }
        }
      }
      .body {
        color: $black;
        font-family: Nunito, sans-serif;
        font-weight: lighter;
      }
    }
  }
}
