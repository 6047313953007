@import url('https://fonts.googleapis.com/css?family=Nunito|Nunito+Sans&display=swap');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import '../node_modules/flexboxgrid/css/flexboxgrid.min.css';
@import './assets/styles/variables.scss';

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  background: $background-color;
  min-height: 100vh;
  //padding-bottom: calc(90px + #{$content-margin});
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nunito Sans', sans-serif;
}

@media (max-width: 768px) {
  body {
    font-size: 0.8rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 0.4rem;
  }
}
