@import '../../assets/styles/variables.scss';

.wrapper {
  position: relative;
  min-height: calc(100vh - 180px);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;

  .content {
    //width: calc(100vw - 2 * #{$content-margin});
    //position: absolute;
    //bottom: $content-margin;
    padding: $content-padding;
    overflow: hidden;

    .heading {
      font-size: 4em;
      margin: 0;
      color: $primary-accent-color;
      text-shadow: $text-shadow;
      text-align: center;
    }

    .container {
      margin-left: 0;
      margin-right: 0;

      .item {
        padding: $content-padding;

        .inner {
          background-color: $white;
          padding: $content-padding;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .content {
    position: relative;
    top: $content-margin;
    padding: $content-padding;
  }
}

@media (min-width: 768px) {
  .content {
    position: absolute;
    bottom: $content-margin;
    padding: $content-padding;
  }
}
