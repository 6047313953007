@import '../../assets/styles/variables.scss';

.wrapper {
  .heading {
    text-align: center;
    padding: $content-padding;
    margin-block-start: 0;
    margin-block-end: 0;
    color: $primary-color;
  }

  .container {
    margin-left: 0;
    margin-right: 0;
  }

  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid $primary-color;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-top: 1rem;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
