@import '../../assets/styles/variables.scss';

.footer {
  //position: fixed;
  //bottom: 0;
  //right: 0;
  //width: 100vw;
  margin: 0;

  height: 90px;
  background: $footer-background-color;
  color: $footer-accent-color;

  font-size: 0.6em;

  .links {
    list-style: none;
    margin: 1em;
    padding: 0;

    .item {
      .link {
        color: $footer-accent-color;
        text-decoration: none;

        &:hover,
        &:visited,
        &:focus {
          color: $footer-accent-color;
          text-decoration: none;
        }
      }
    }
  }
}
