@import '../../assets/styles/variables.scss';

.wrapper {
  background: $white;
  height: 100%;
  .imageContainer {
    width: 100%;
    max-height: 50vh;
    overflow: hidden;
    position: relative;

    .image {
      width: 100%;
      height: 50vh;
      object-fit: cover;
    }

    .slogan {
      position: absolute;
      bottom: 0;
      left: 0;
      color: $white;

      margin: 1em;
      font-size: 3em;
      line-height: 2em;
      text-shadow: $text-shadow;
    }
  }

  .links {
    margin: 0;
    padding: 0.5rem;
    .item {
      padding: 0.5em 0.5rem;

      &:nth-child(2) {
        padding: 0.5em 0.5rem;
      }

      /*&:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }*/
    }

    .contact {
      background: $background-color;
      padding: $content-padding;
      cursor: pointer;

      .icon {
        width: 15px;
        text-align: right;
        margin-right: 5px;
      }
    }
  }
}
